// Adobe Fonts
@import url("https://use.typekit.net/guh8ssh.css");

@font-face {
  font-family: "BameBlack";
  src: local("BameBlack"), url("../../fonts/Bame-Black.ttf") format("truetype");
}

@font-face {
  font-family: "BameBold";
  src: local("BameBold"), url("../../fonts/Bame-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Bame";
  src: local("Bame"), url("../../fonts/Bame-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Ranade";
  src: local("Ranade"), url("../../fonts/Ranade-Regular.otf") format("opentype");
}

body,
html {
  color: $secondary;
  font-family: "all-round-gothic", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
  overflow-x: hidden;
  background-color: $gray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: $primary;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 600;
  line-height: 1.3em;
  font-family: "Bame";
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.cs-primary_font {
  font-family: "Ranade";
  letter-spacing: 0.06em;
}

.cs-secondary_font {
  font-family: "all-round-gothic", sans-serif;
}

h1 {
  font-size: 56px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  margin-bottom: 15px;
}

ul {
  margin: 0 0 25px 0;
  padding-left: 20px;
  list-style: square outside none;
}

ol {
  padding-left: 20px;
  margin-bottom: 25px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 15px;
  font-style: italic;
  font-size: 20px;
  line-height: 1.6em;
  margin: 0;
}

address {
  margin: 0 0 15px;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}

button {
  color: inherit;
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: none;
  color: $accent;
}

table {
  width: 100%;
  margin-bottom: 25px;

  th {
    font-weight: 600;
    color: $secondary;
  }

  td,
  th {
    border-top: 1px solid $border;
    padding: 11px 10px;
  }
}

dl {
  margin-bottom: 25px;

  dt {
    font-weight: 600;
  }
}

b,
strong {
  font-weight: bold;
}

pre {
  color: $secondary;
  border: 1px solid $border;
  font-size: 18px;
  padding: 25px;
  border-radius: 5px;
}

kbd {
  font-size: 100%;
  background-color: $secondary;
  border-radius: 5px;
}

@media screen and (max-width: 991px) {
  body,
  html {
    font-size: 16px;
    line-height: 1.6em;
  }
  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
}
input,
textarea {
  color: $primary;
}
